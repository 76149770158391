import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import ListFacilities from "@components/facilities/List"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "facilities.getAll"
}

const RegionListPage = (props) => (
  <>
    <Seo title="Facilities List" />
    <div className="main-wrapper">
      <Sidebar menuActive="settings" />
      <ListFacilities
        addBtnText="Facility"
        menu="Facilities"
        parentPath="facilities"
        pageType="List"
        additionalParams={AdditionalParams} />
    </div>
  </>
)

export default RegionListPage;
